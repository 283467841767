<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <!-- Основной таб -->
          <b-tab :title="$t('COMMON.TAB_MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name-input"
              >
                <b-form-input
                  id="field-name-input"
                  :state="validateDtoState('name')"
                  v-model="dto.name"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                  :state="validateDtoState('name')"
                >
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="field-epsilon"
                label-cols-lg="2"
                label="Epsilon"
                label-for="field-epsilon-input"
              >
                <b-form-input
                  id="field-epsilon-input"
                  type="number"
                  v-model="dto.epsilon"
                  :state="validateDtoState('epsilon')"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-epsilon-live-feedback"
                  :state="validateDtoState('epsilon')"
                >
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card-body>
          </b-tab>

          <!-- Таб сегментов -->
          <b-tab :title="$t('POWERLINE.SEGMENTS')" :disabled="!dto.id">
            <div class="container-fluid">
              <h3 class="title">{{ $t('POWERLINE.PARAMETERS_OF_SEGMENTS_AND_VERTICES') }}</h3>
              <p class="subtitle">{{ $t('POWERLINE.FOR_EACH_PAIR_OF_VERTICES_YOU_NEED_TO_SET') }}</p>
              <ul class="list">
                <li class="list-item">
                  {{ $t('POWERLINE.SEGMENT_NUMBER_CONNECTING_THEM') }} ({{ $t('POWERLINE.EMPTY_IF_VERTICES_ARE_NOT_CONNECTED') }});
                </li>
                <li class="list-item">
                  {{ $t('POWERLINE.DISTANCE_BETWEEN_VERTICES_IN_METERS') }} ({{ $t('POWERLINE.EMPTY_IF_VERTICES_ARE_NOT_CONNECTED') }});
                </li>
                <li class="list-item">
                  {{ $t('POWERLINE.SIGNAL_SPEED_IN_METERS_PER_SECOND') }} ({{ $t('POWERLINE.EMPTY_IF_VERTICES_ARE_NOT_CONNECTED') }});
                </li>
              </ul>

              <div class="mb-3 d-flex align-items-center">
                <div class="me-3 flex-grow-1">
                  <label for="vertexCount" class="form-label">
                    {{ $t('POWERLINE.ENTER_THE_TOTAL_NUMBER_OF_VERTICES') }}
                  </label>
                  <input
                    v-model.number="vertexCount"
                    @input="handleVertexCountChange"
                    type="number"
                    id="vertexCount"
                    class="form-control"
                    min="2"
                    step="1"
                    :placeholder="$t('POWERLINE.ENTER_THE_TOTAL_NUMBER_OF_VERTICES')"
                  />
                </div>
              </div>

              <!-- Таблица сегментов -->
              <div
                v-if="matrixSize > 0"
                class="table-responsive"
                style="height: calc(100vh - 450px); width: 100%; overflow: auto"
              >
                <table class="table table-bordered" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="fixed-cell">Вершины</th>
                      <th class="fixed-cell" v-for="i in matrixSize" :key="'header-' + i">
                        {{ i }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="j in matrixSize" :key="'row-' + j">
                      <td class="fixed-cell">
                        {{ j }}
                      </td>
                      <td v-for="i in matrixSize" :key="'cell-' + j + '-' + i">
                        <div v-if="i < j">
                          <div>{{ j }}-{{ i }}</div>
                          <div>
                            <label>{{ $t('POWERLINE.DISTANCE_BETWEEN_VERTICES_IN_METERS') }}:</label>
                            <input
                              :value="getSegment(j, i).distance"
                              @input="updateSegment(j, i, 'distance', $event.target.value)"
                              type="number"
                              :placeholder="$t('POWERLINE.DISTANCE_BETWEEN_VERTICES_IN_METERS')"
                              class="form-control"
                            />
                          </div>
                          <div>
                            <label>{{ $t('POWERLINE.SIGNAL_SPEED_IN_METERS_PER_SECOND') }}:</label>
                            <input
                              :value="getSegment(j, i).speed"
                              @input="updateSegment(j, i, 'speed', $event.target.value)"
                              type="number"
                              :placeholder="$t('POWERLINE.SIGNAL_SPEED_IN_METERS_PER_SECOND')"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import ApiService from "@/core/services/api.service";
import axios from "axios";

export default {
  name: "Powerline",
  data() {
    return {
      showOverlay: false,
      dto: {
        id: null,
        name: "",
        epsilon: 1,
        teamId: ApiService.teamData.value,
      },
      save: {
        resource: "/api/oscillogram/powerline",
        requestType: "POST",
        dto: null,
      },
      get: {
        resource: "/api/oscillogram/powerline/",
        requestType: "GET",
      },
      vertexCount: 2,
      matrixData: {},
      maxSegmentId: 0,
      removedSegments: [],
      isLoading: false
    };
  },

  computed: {
    matrixSize() {
      return this.vertexCount;
    }
  },

  methods: {
    validateDtoState(field) {
      if (field === "name") {
        return this.dto.name != null && this.dto.name.length > 0;
      }
      if (field === "epsilon") {
        return this.dto.epsilon != null && this.dto.epsilon > 0;
      }
      return true;
    },

    onSaveClicked() {
      if (!this.validateDtoState("name") || !this.validateDtoState("epsilon")) {
        this.$bvToast.toast(this.$t("COMMON.FILL_REQUIRED_FIELDS"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }

      this.showOverlay = true;
      this.save.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, this.save)
        .then((response) => {
          if (this.dto.id) {
            return this.saveSegments();
          }
          return response;
        })
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$router.push({ name: "powerlines" });
        })
        .catch(this.onError)
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleVertexCountChange() {
      const newVertexCount = this.vertexCount;
      console.log("VertexCount: " + newVertexCount);

      if (newVertexCount < this.matrixSize) {
        console.log("/ Удаляем вершины и связанные с ними сегменты: " + newVertexCount);
        for (let i = this.matrixSize; i > newVertexCount; i--) {
          this.removeVertex(i);
        }
      }
    },

    getSegment(row, col) {
      const existingSegment = Object.values(this.matrixData).find(
        (item) =>
          (item.vertexAid === row && item.vertexBid === col) ||
          (item.vertexAid === col && item.vertexBid === row)
      );

      return existingSegment || { distance: 0, speed: 0 };
    },

    updateSegment(row, col, field, value) {
      let segmentId = this.findEntryId(row, col);

      const numericValue = parseFloat(value);
      const validValue = isNaN(numericValue) ? 0 : numericValue;

      if (segmentId) {
        this.$set(this.matrixData[segmentId], field, validValue);
      } else {
        segmentId = this.getNextId();
        this.$set(this.matrixData, segmentId, {
          vertexAid: row,
          vertexBid: col,
          distance: field === "distance" ? validValue : 0,
          speed: field === "speed" ? validValue : 0,
        });
      }
    },

    findEntryId(row, col) {
      for (const [id, segment] of Object.entries(this.matrixData)) {
        if (
          (segment.vertexAid === row && segment.vertexBid === col) ||
          (segment.vertexAid === col && segment.vertexBid === row)
        ) {
          return id;
        }
      }
      return null;
    },

    getNextId() {
      this.maxSegmentId += 1;
      return this.maxSegmentId.toString();
    },

    removeVertex(vertexId) {
      Object.entries(this.matrixData).forEach(([id, item]) => {
        if (item.vertexAid === vertexId || item.vertexBid === vertexId) {
          this.removedSegments.push({
            segmentPosition: `${item.vertexAid}-${item.vertexBid}`,
          });
          this.$delete(this.matrixData, id);
        }
      });
    },

    saveSegments() {
      const filteredSegments = {};
      Object.entries(this.matrixData).forEach(([id, segment]) => {
        if (
          segment.vertexAid <= this.vertexCount &&
          segment.vertexBid <= this.vertexCount
        ) {
          filteredSegments[id] = segment;
        }
      });

      // Отправляем удаление сегментов
      const deletePromises = this.removedSegments.map(segment => {
        return axios.delete("api/oscillogram/segment/delete", {
          data: {
            powerlineId: this.dto.id,
            segmentPosition: segment.segmentPosition,
          },
        });
      });

      // Отправляем обновленные данные
      return Promise.all(deletePromises)
        .then(() => {
          return axios.post("api/oscillogram/segment/grid", {
            powerlineId: this.dto.id,
            segments: filteredSegments,
          });
        });
    },

    loadData() {
      if (this.$route.params.id) {
        this.showOverlay = true;
        this.get.resource = "/api/oscillogram/powerline/" + this.$route.params.id;
        this.$store
          .dispatch(API_REQUEST, this.get)
          .then((response) => {
            this.dto = response;
            return this.loadSegments();
          })
          .catch(this.onError)
          .finally(() => {
            this.showOverlay = false;
          });
      }
    },

    loadSegments() {
      if (!this.dto.id) return Promise.resolve();
      
      return axios.get("api/oscillogram/segment", {
        params: { powerlineId: this.dto.id }
      })
      .then(response => {
        this.matrixData = {};
        this.maxSegmentId = 0;
        this.removedSegments = [];

        Object.entries(response.data.segments).forEach(([id, segment]) => {
          const segmentId = parseInt(id, 10);
          this.maxSegmentId = Math.max(this.maxSegmentId, segmentId);

          this.$set(this.matrixData, segmentId, {
            vertexAid: segment.vertexAid,
            vertexBid: segment.vertexBid,
            distance: segment.distance || 0,
            speed: segment.speed || 0,
          });
        });

        const vertexIds = Object.values(response.data.segments).flatMap(
          (segment) => [segment.vertexAid, segment.vertexBid]
        );
        this.vertexCount = vertexIds.length > 0 ? Math.max(...vertexIds) : 2;
      });
    },

    onError(error) {
      let message = this.$t("COMMON.UNKNOWN_ERROR");

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (error.request) {
        message = this.$t("COMMON.NETWORK_ERROR");
      }

      this.$bvToast.toast(message, {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });
    },
  },

  created() {
    this.loadData();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        text: this.$t("MENU.POWERLINE"),
        to: "/powerlines",
      },
      {
        text: this.$route.params.id
          ? this.$t("DEVICE.EDIT_POWERLINE")
          : this.$t("COMMON.CREATE"),
      },
    ]);

    this.$store.dispatch(SET_ACTIONS, []);
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: top;
}

button {
  margin-left: 5px;
  cursor: pointer;
}

div {
  margin: 2px 0;
}

.container-fluid {
  max-width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.table-responsive {
  max-width: 100%;
  overflow: auto;
  padding: 15px;
}

.table {
  margin-top: 20px;
  text-align: center;
  table-layout: fixed;
}

.fixed-cell {
  width: 150px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  border: 1px solid #3333331c;
}

.fixed-cell input.form-control {
  width: 130px;
  height: 30px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}

.table-bordered {
  border-color: #333 !important;
}

.table thead th,
.table tbody td {
  border-color: #333 !important;
  background-color: #f9f9f9;
}

.table thead th {
  background-color: #dddddd9c;
  font-weight: bold;
}

.table tbody td {
  background-color: #fff;
}

.form-select {
  width: 100%;
  height: 38px;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.75rem;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  font-size: 1.125rem;
  color: #666;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.list-item:last-child {
  border-bottom: none;
}

.list-item:before {
  content: "•";
  color: #999;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
}
</style>
